.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-bottom: 5px;
  margin-top: -50px;
}

.App-header {
  background-color: #f2f2f2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #110a40;
  background-image: url("./assets/images/wave.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}

.App-header p {
  max-width: 90vw;
}

.App-link {
  color: #0086fe;
  font-size: calc(10px + 1vmin);
}

.StoresContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}

.ImageContainer {
  width: 100%;
  height: 60px;
  margin-top: 15px;
  cursor: pointer;
}

.Store {
  height: 100%;
}

@media (min-width: 850px) {
  .App-header p {
    max-width: 850px;
  }
  .StoresContainer {
    flex-direction: row;
    justify-content: center;
  }

  .ImageContainer {
    width: auto;
    cursor: pointer;
  }

  .Store {
    margin-right: 20px;
  }
}
